<!--
 * @Author: 小洋人
 * @Date: 2024-07-10 15:51:38
 * @LastEditors: 小洋人
 * @LastEditTime: 2024-08-14 14:39:23
 * @FilePath: \kangmisi-officialwebsite\src\views\customerCases.vue
 * @email: 1172457354@qq.com
 * @Description: 
-->
<template>
  <div class="page" id="page">
    <heade :active="4"></heade>
    <div style="height: 64px"></div>
    <div class="page-bg" :style="{ height: bannerHeight + 'px' }">
      <img
        src="../assets/images/customerCases/bg.png"
        alt=""
        :style="{ height: bannerHeight + 'px' }"
      />
      <div class="page-text">
        <div class="china">客户案例</div>
        <div class="english">Customer stories</div>
      </div>
    </div>
    <!-- 主题内容 -->
    <div class="content">
      <div v-for="(item, index) in list" :key="index" class="list">
        <div class="list-title">
          <img :src="item.icon" alt="" />
          <div class="text">
            {{ item.text }}
          </div>
        </div>
        <div class="item-list">
          <div v-for="(ele, i) in item.list" :key="i" class="item">
            <img
              :src="
                require(`@/assets/images/customerCases/${item.key}${i + 1}.png`)
              "
              alt=""
            />
            <div class="ele-title">
              <div class="title">
                {{ ele.title }}
              </div>
              <div v-html="ele.html" class="html"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template>
      <bottom></bottom>
    </template>
  </div>
</template>

<script>
import bottom from "./bottom.vue";
import heade from "./header.vue";

export default {
  components: { heade, bottom },
  data() {
    return {
      bannerHeight:0,
      list: [
        {
          icon: require("../assets/images/customerCases/person.png"),
          key: "p",
          text: "人员位置监管",
          list: [
            {
              title: "国家电网",
              html: `帮助国家电网<span style='color: #0F3391;font-weight: Bold;'>30000+</span>巡检人员实现数字化管理，提高巡检工作效率，故障维修处理，<span style='color: #0F3391;font-weight: Bold;'>2600</span>多辆巡检车，解决了公车公车私用的问题。`,
            },
            {
              title: "中国铁通",
              html: "<span style='color: #0F3391;font-weight: Bold;'>20000+</span>网络巡检、宽带安装服务人员定位管理，通过监控外勤作业人员的服务时间和人员定位轨迹，对企业统一管理外勤作业人员提供了极大的便利。",
            },
            {
              title: "晋江环卫",
              html: "<span style='color: #0F3391;font-weight: Bold;'>5000+</span>环卫工人定位管理，考勤管理、排班调度。",
            },
            {
              title: "中来集团",
              html: "<span style='color: #0F3391;font-weight: Bold;'>1000+</span>光伏外勤巡检施工定位管理，考勤管理、排班调度。",
            },
            {
              title: "碧桂园",
              html: "<span style='color: #0F3391;font-weight: Bold;'>3000+</span>物业保洁人员定位、作业轨迹、作业覆盖率，作业流程标准化，达到集约化管理。",
            },
            {
              title: "杭州环卫",
              html: "上城区、拱墅区、临安、余杭区等区县<span style='color: #0F3391;font-weight: Bold;'>5000+</span>环卫工人定位管理，考勤管理、排班调度。",
            },
            {
              title: "中国移动",
              html: "<span style='color: #0F3391;font-weight: Bold;'>2000+</span>中国移动通信巡检人员定位管理，考勤管理、排班调度。",
            },
            {
              title: "围场满族蒙古族自治县森林草原防灭火保障中心",
              html: "<span style='color: #0F3391;font-weight: Bold;'>2000+</span>森林巡检人员定位管理，考勤管理、排班调度，提高作业效率。",
            },
          ],
        },
        {
          icon: require("../assets/images/customerCases/car.png"),
          key: "c",
          text: "车辆位置监管",
          list: [
            {
              title: "象屿",
              html: "为象屿提供<span style='color: #0F3391;font-weight: Bold;'>30w+</span>车载GPS定位器结合人工智能汽车金融风控平台，有效保障了汽车融资租赁、车辆按揭贷款、以租代购等汽车金融资产安全，降低了潜在风险和损失。",
            },
            {
              title: "海康威视",
              html: "<span style='color: #0F3391;font-weight: Bold;'>5w+</span>车辆智能终端供应。",
            },
            {
              title: "运满满",
              html: "对<span style='color: #0F3391;font-weight: Bold;'>50w+</span>货物运输挂靠车辆进行了统一的管理，帮助企业解决了挂靠货运约束难的痛点。",
            },
            {
              title: "中储智运",
              html: "通过LOT智能设备，不间断上传车辆和船舶的位置信息，极快地帮助企业实现管理货物运输过程中的人员管理和位置管理。",
            },
            {
              title: "浙江大学",
              html: "公车管理、排班调度、用车申请等，公务车辆的高效高质量管理。",
            },
            {
              title: "宇通集团",
              html: "<span style='color: #0F3391;font-weight: Bold;'>3w+</span>车辆出厂智能终端配备。",
            },
            {
              title: "海螺水泥",
              html: "<span style='color: #0F3391;font-weight: Bold;'>10w+</span>水泥运输车辆配备智能电子终端，可从入厂、装货、运输、卸货全流程监控运货时长、载重、行为、地点，实现数字化管理。",
            },
            {
              title: "绿源",
              html: "<span style='color: #0F3391;font-weight: Bold;'>30w+</span>智能终端+云平台，帮助传统车厂两轮车智能化升级，可实现体验蓝牙感应开关锁、一键开座桶、智能防盗、定位追踪等功能。",
            },
          ],
        },
        {
          icon: require("../assets/images/customerCases/asset.png"),
          key: "a",
          text: "资产位置管理",
          list: [
            {
              title: "徐工集团",
              html: "资产定位管控，挖掘机等大型工程设备租赁，通过监控车辆的工作状态，作业时长，远程管理设备的工作状态，大大提高了企业对资产的利用率，以及租赁过程中的风险管控。",
            },
            {
              title: "远兆物流",
              html: "安装便携式可充电智能定位终端，实现票据回签单管理，同时跟随车辆产生运行轨迹，实现了车辆管理。",
            },
            {
              title: "建设银行",
              html: "票据、文件、材料等重要级资产包配备智能设备，实现重要资产的定位管理、风险把控。",
            },
            {
              title: "迪安诊断",
              html: "通过带温湿度监测功能的lot智能设备，达到了对样本的时效管理，样本的温湿度监测，样本轨迹监控，极大了提高了样本运输过程中的风险管控。",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.imgLoag();
    window.addEventListener(
      "resize",
      () => {
        this.imgLoag();
      },
      false
    );
  },
  methods: {
    imgLoag() {
      this.$nextTick(() => {
        const page = document.getElementById("page");
        const pageWidth = page.offsetWidth;
        this.bannerHeight = (pageWidth * 560) / 1920;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.page-bg {
  width: 100vw;
  position: relative;
  img {
    width: 100vw;
  }
}
.page-text {
  position: absolute;
  top: 42%;
  left: 19.1%;
  color: #fefefe;
  // text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.16);
  .china {
    font-weight: 300;
    font-size: 40px;
  }
  .english {
    font-weight: 300;
    font-size: 24px;
    margin-top: 3px;
  }
}
.page {
  background-color: #fff;
}
.content {
  width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
  margin-top: 35px;
}
.list {
  padding: 48px 0;
  border-bottom: 1px solid #dbdbdb;
  .list-title {
    display: flex;
    img {
      width: 40px;
      height: 40px;
    }
    .text {
      font-size: 32px;
      color: #2c2c2c;
      margin-left: 5px;
    }
  }
  .item-list {
    display: flex;
    flex-wrap: wrap;
    .item {
      margin-right: 24px;
      margin-top: 40px;
      width: 282px;
      background: #ffffff;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    }
    .item:nth-child(4n) {
      margin-right: 0px;
    }
    .ele-title {
      padding: 16px;
      .title {
        font-weight: bold;
        font-size: 16px;
        color: #2c2c2c;
      }
      .html {
        font-weight: 300;
        font-size: 14px;
        color: #2c2c2c;
        margin-top: 10px;
      }
    }
  }
}
</style>
